import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export const CommentArea = styled(Card)`
 .MuiCardHeader-title{
    font-size: 18px;
    font-weight: bold;
    color: #ef4e45;
    text-align: center;
  }
  .carousel-initialized{
    padding: 0px 81px;
  }
`
