import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CommentArea } from './styles'
import Avatar from '@material-ui/core/Avatar'
import Grid  from '@material-ui/core/Grid'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { FormatQuoteRounded as FormatQuoteRoundedIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  xLarge: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  m1: {
    margin: '10px 0',
  },
  floatRight: {
    float: 'right',
  },
  headerComment:{
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '10px'
  },
  cardSize:{
    // minWidth: '470px',
    minHeight: '450px',
    marginBottom: '10px',
    marginTop: '10px'
  },
  descriptionComment:{
    fontSize: '15px',
    color: '#101010'
  }
}))

export default function Comment(props) {
  const classes = useStyles()
  return (
    <>
      <CommentArea elevation={4} className={classes.m1, classes.cardSize}>
        <FormatQuoteRoundedIcon
          fontSize="large"
          className={classes.floatRight}
        /> 
        <Grid>
          <Grid xs={12} lg={12} component="div" alignItems="center" className={classes.headerComment}>
                <Avatar
                  aria-label="recipe"
                  src={props.image}
                  className={classes.xLarge}/>
                </Grid>
              <CardHeader
                title={props.title}
              />
        </Grid>
        <CardContent component="div" className={classes.descriptionComment}>
            {props.description}
        </CardContent>
      </CommentArea>
    </>
  )
}
