import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { CommentArea } from './styles'
// import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
// import CardHeader from '@material-ui/core/CardHeader'
// import CardContent from '@material-ui/core/CardContent'
import { FormatQuoteRounded as FormatQuoteRoundedIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  imgSize: {
    // width: 252,
    height: '17vw',
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center top',
    [theme.breakpoints.down('md')]: {
      height: '105vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      backgroundPosition: 'center',
      height: '105vw',
    },
  }
}))

export default function Banner(props) {
  const classes = useStyles()
  return (
    <a href={props.link} target="_blank">
      <Grid>
        <div style={{ background: 'url(' + props.img + ')' }} className={classes.imgSize}></div>
      </Grid>
    </a>
  )
}
