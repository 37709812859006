import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import qrCodeEmec from '../../images/qrcode_15133.png'
import eMECLogo from '../../images/emec.png'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  qrCodeContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '16vw',
    border: '4px solid #0b649e',
    borderRadius: '12px',
    padding: '24px 0',
    [theme.breakpoints.down('md')]: {
      width: '88vw',
      marginBottom: '40px',
    },
  },
  qrCodeImage: {
    width: '13vw',
    [theme.breakpoints.down('md')]: {
      width: '55vw',
    },
  },
  eMECLogo: {
    width: '6vw',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '25vw',
    },
  },
  ieLogo: {
    width: '6vw',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      width: '25vw',
    },
  },
}))

const QrCodeImage = props => {
  const classes = useStyles()
  return (
    <>
      <Grid container className={classes.qrCodeContainer}>
        <img src={eMECLogo} className={classes.eMECLogo} />
        <img src={props.img} className={classes.qrCodeImage} />
        <img src={props.ieLogo} className={classes.ieLogo} />
      </Grid>
    </>
  )
}

export default QrCodeImage
